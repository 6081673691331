

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

@Component
export default class ApplyNewLoanComponent extends Vue {
  public ediFile = null;
  public async checkEdiFile() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getEdiFile",
        { loanTxnId: this.$route.query.id });
      this.ediFile = response.data.ediFile;
    } catch (error) {
      console.log(error);
    }
  }

  mounted(){
      this.checkEdiFile();
  }
}
